<!--  -->
<template>
   <layout>
       <div class="search-top">
            <div class="contant">
                <div class="contant-top">
                <div class="contant-logo">
                    <img src="@/assets/home/logo.png" alt @click="$router.push('/')" />
                    <span>爱玩就上爱租号</span>
                </div>
                <div class="contant-search">
                        <input type="text" placeholder="搜你想要的~" v-model="valueList" />
                        <button @click="goSerach">搜索</button>
                </div>
                </div>
                <div class="contant-bottom">
                    <div class="contant-hot">
                        <img src="@/assets/home/hot.png" alt="">
                        <span>热门游戏</span>
                         <div class="newClass">
              <hotlist></hotlist>
            </div>
                    </div>
                    <ul>
                    <li @click="toClick(0)">首页</li>
                    <li :class="{active: isShow == 1}" @click="toClick(1)">爱租号</li>
                    <li @click="toClick(2)">工具下载</li>
                </ul>
                </div>
            </div>
        </div>
        <div class="novice">
            <img src="../../assets/novice/banner.png" alt="">
            <img src="../../assets/novice/02.png" alt="">
            <img src="../../assets/novice/03.png" alt="">
            <img src="../../assets/novice/04.png" alt="">
            <img src="../../assets/novice/05.png" alt="">
            <img src="../../assets/novice/06.png" alt="">
        </div>
   </layout>
</template>
 
<script>
import layout from "../../components/layout";
import hotlist from '../../components/hotlist'

export default {
 name: 'novice',
 data () {
 return {
     isShow : 1,
     valueList: ''
   }
  },
  components: {
    layout,
    hotlist
  },
  methods: {
      toClick(id) {
            if(id == 0){
         this.$router.push({
          path:'/home'
        })
      }else if(id == 2) {
        this.$router.push({
          path:'/download'
        })
      }else{
           this.$router.push({
          path:'/lobby'
        })
      }
          this.isShow = id
      },
     goSerach() {
        if(this.valueList){
            this.$router.push({
            path: '/lobby',
            query: {
            key_word: this.valueList
            }
          })
        }
      
    },
  }
}
</script>
 
<style lang='scss' scoped >
 .search-top{
     width: 100%;
     background-color: #fff;
     height: 168px;
     .contant{
         width: 1200px;
         height: 100%;
         margin: 0 auto;
         .contant-top{
             display: flex;
             align-items: center;
             justify-content: space-between;
             margin:0 0 40px;
             height: 86px;
             .contant-logo{
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                 img{
                      width: 218px;
                      height: 55px;
                      margin-right: 18px;
                 }
                 span{
                     font-size: 15px;
                 }
             }
             .contant-search{
                 width: 480px;
                 height: 48px;
                 background: #ffffff;
                 border: 2px solid #3c7efe;
                 border-right: none;
                 border-radius: 24px;
                 box-sizing: border-box;
                 display: flex;
                 
                 input{
                     width: 100%;
                     height: 100%;
                     border: none;
                     outline-style: none;
                    border-radius: 24px;
                    text-indent: 2em;
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #A2A6B8;
                 }
                 button{
                    position: relative;
                    width: 120px;
                    height: 48px;
                    background: #3c7efe;
                    border-radius: 24px;
                    color: #ffffff;
                    font-size: 18px;
                    box-sizing: border-box;
                    margin-top: -2px;
                    padding-left: 20px;
                    cursor: pointer;
                 }
                  button::after {
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url(../../assets/home/ss.png) no-repeat center center;
                    background-size: 100% 100%;
                }
             }
         }
         .contant-bottom{
             display: flex;
             .contant-hot{
                 width: 200px;
                 height: 42px;
                 border-radius: 12px 12px 0 0;
                 background-color: #3c7efe;
                 color: #fff;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                   position: relative;
                   cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
                 img{
                     width: 16px;
                     height: 18px;
                     margin-right: 12px;
                 }
                 span{
                    font-size: 16px;
                    color: #FFFFFF;
                 }

                 
        .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
        }
             }
             ul{
                 display: flex;
                 align-items: center;
                 li{
                    font-size: 14px;
                    color: #666;
                    padding: 0 30px;
                    // height: 100%;
                    line-height: 17px;
                    position: relative;
                    cursor: pointer;
                 }
                 .active{
                   color: #000;
                   font-weight: bold;
                 }
                 .active:after {
                    position: absolute;
                    bottom: -9px;
                    left: 50%;
                    margin-left: -7px;
                    display: block;
                    content: "";
                    width: 14px;
                    height: 3px;
                    background-color: #3b7dfd;
                }
             }
         }
     }
 }
//  
.novice{
    img{
        width: 100%;
        height: 100%;
        display: block;
    }
}
</style>